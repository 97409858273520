module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Trista","short_name":"Trista","start_url":"/","background_color":"#267776","theme_color":"#ffffff","display":"standalone","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"070cded76f12983ceeec7574dd50e9fc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-159952522-1","head":false,"respectDNT":true,"exclude":[],"pageTransitionDelay":0,"cookieDomain":"auto"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
